<template>
	<div class="bilanContainer">
		<div class="textContainer">
			<p class="text">Félicitations, tu as soulevé</p>
			<p class="poids">{{poidsKg}}</p>
			<img src="../assets/weight.svg" width="170" height="170"/>
			<p class="text">{{duree}}</p>
			<p class="weightComp">Soit l'équivalent de <span>{{poidsCompa}}</span></p>
		</div>
		<button @click="openNew"><img src="../assets/plus.svg" width="60" height="60"></button>
	</div>
</template>

<script>
const headers = { "Access-Control-Allow-Origin" : "*" }
import axios from 'axios';
import $API_URL from '../global/constants.js'
export default{
	name: "bilan",
	data: function() {
		return {
			poidsTotal: 0,
			exercicesTab: [],
			dureeTotale: 0,
			weightTab: [{name : "baleines",poidsElem : 130000}, {name : "voitures" , poidsElem : 1200}, {name : "chats" ,poidsElem : 5}, {name : "packs d'eau" ,poidsElem : 9}, {name : "hélicoptères d'attaque" ,poidsElem : 6600}, {name : "Marcos" ,poidsElem : 70}],
			poidsComp : ""
		}
	},
	methods: {
		async getTotalWeight(){
			await axios.get('https://back-gomuscu.onrender.com/exercice', headers)
			.then(response => {
				let exercicesTable = response.data
				this.exercicesTab = exercicesTable
				let poids = 0
				exercicesTable.forEach(exercice => poids += (exercice.poids * exercice.series * exercice.repetitions))
				this.poidsTotal = poids
				let index = Math.floor(Math.random() * this.weightTab.length)
				let ratio = Math.round(poids/this.weightTab[index].poidsElem) 
				this.poidsComp = ratio.toString() + " " + this.weightTab[index].name
			})
			.catch(e => {
			})

			this.exercicesTab.sort(function(a, b) {
			    var c = new Date(a.createdAt).getTime();
			    var d = new Date(b.createdAt).getTime();
			    return d-c;
			});


			if(this.exercicesTab.length > 0){
				let today = new Date().getTime();
				let start = new Date(this.exercicesTab[this.exercicesTab.length -1].createdAt).getTime();
				var difference = today - start
				this.dureeTotale = Math.ceil(difference / (1000 * 3600 * 24))
			}
			
		},
		openNew() {
			this.$router.push('/ajoutExercice')
		}
	},
	created(){
		this.getTotalWeight()
	},
	computed: {
		poidsKg: function () {
      		return this.poidsTotal + " kg"
    	},
    	duree: function() {
    		if(this.dureeTotale != 1 ){
    			return "En " + this.dureeTotale + " jours"
    		}
    		else {
    			return "En " + this.dureeTotale + " jour"
    		}
    	},
		poidsCompa: function () {
			return this.poidsComp
		}
	}
}
</script> 


<style scoped>

.bilanContainer {
	display: flex;
	height: 75vh;
}

.textContainer {
	margin: auto;
}

.text {
	font-size: 26px;
	margin-left: 2vw;
	margin-right : 2vw;
	margin-bottom : 2vh;
}

.poids {
	font-size: 32px;
	font-weight: bold;
	margin-bottom : 2vh;
	margin-top: 2vh;
}

button {
	position: absolute;
	background-color: white;
	right: 4%;
	bottom: 2%;
	border: none;
}

.weightComp {
	display: block;
}


</style>
