<template>
	<div class="bilanContainer">
		<div class="inputContainer"><form action="">
				<input placeholder="Rechercher" autocorrect="off" spellcheck="false" v-model="nom" @change="filterExercices()"/>
			</form>
		</div>
		<div v-for="(exercice, index) in exercicesTab" class="textContainer">
			<span><strong>{{exercice.nom}}</strong></span>
			<span>{{weightForme(exercice)}}</span>
			<span>{{seriesForme(exercice)}}</span>
			<!-- <span>{{exercice.repetitions}}</span> -->
			<span class="text">{{dateForme(exercice)}}</span>
		</div>
	</div>
</template>

<script>
const headers = { "Access-Control-Allow-Origin" : "*" }
import axios from 'axios';
import $API_URL from '../global/constants.js'
export default{
	name: "RecapExercices",
	data: function() {
		return {
			exercicesTab: [],
			exercicesTabFiltered: [],
			nom: ""
		}
	},
	methods: {
		async getTotalWeight(){
			await axios.get('https://back-gomuscu.onrender.com/exercice', headers)
			.then(response => {
				let exercicesTable = response.data
				this.exercicesTab = exercicesTable
				this.exercicesTabFiltered = exercicesTable
			})
			.catch(e => {
			})

			this.exercicesTab.sort(function(a, b) {
			    var c = new Date(a.createdAt).getTime();
			    var d = new Date(b.createdAt).getTime();
			    return d-c;
			});

			
		},
		dateForme(exercice) {
			// var dete = new Date(this.createdAt)
			var dete1 = exercice.createdAt
			let m = dete1.substring(5,7)
			let j = dete1.substring(8,10)
			let y = dete1.substring(0,4)
			return j + "/" + m + "/" + y;
    	},
		weightForme(exercice){
			let poids = exercice.poids
			return poids + " kg"
		},
		seriesForme(exercice){
			let exo = exercice.series + "x" + exercice.repetitions
			return exo
		},
		filterExercices() {
			console.log(this.nom)
		}
	},
	created(){
		this.getTotalWeight()
	},
	watch: {
		nom:function(){
			this.exercicesTab = this.exercicesTabFiltered
			let tempNom = this.nom
				let tabTemp = []
				this.exercicesTab.forEach(function(exercice){
					if(exercice.nom.toUpperCase().includes(tempNom.toUpperCase())){
						tabTemp.push(exercice)
					}
				})
				this.exercicesTab = tabTemp
		}
	}
}
</script> 

<style scoped>

.bilanContainer{
	margin-top: 20px;
}

span{
	margin: auto;
	vertical-align: middle !important;
	font-size: 14px;
}

.textContainer{
	background-color: #F8F8F8;
	display: grid;
	grid-template-columns: 2fr 1fr 1fr 1fr;
	margin: 10px;
	height : 10vh;
	border-radius: 5px;
	box-shadow: 5px 5px 5px darkgray;
}

.text{
	margin-right: 10px;
}

input {
	width: 93vw;
	height: 30px;
	margin: auto;
	border-radius : 8px;
	border: solid 1px darkgray
}

</style>