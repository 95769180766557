<template>
	<div class="ajoutWrapper">
		<meta 
     name='viewport' 
     content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0' 
/>
		<img src="../assets/back_arrow.png" @click="home"/>

		<div class="form">
			<p class="ajoutTitle">Ajouter un exercice</p>
			<form action="">
				<input placeholder="Exercice" autocorrect="off" spellcheck="false" v-model="nom" required/>
				<input placeholder='Poids' v-model="poids" required type="number" max="999"/>
				<input placeholder="Series" v-model="series" required type="number" max="99"/>
				<input placeholder="Répétitions" v-model="repetitions" required type="number" max="99"/>
				<button type="submit" @click="createExercice" class="validationButton">Valider</button>
			</form>
		</div>
	</div>
</template>


<script>
import axios from 'axios';
const headers = { "Access-Control-Allow-Origin" : "*" }
export default {
	name: "ajoutExercice",
	data: function() {
		return {
			nom: null,
			series: null,
			poids: null,
			repetitions: null,
		}
	},
	methods: {
		home(){
			this.$router.push('/')
		},
		submit: async function(){
			await this.createExercice()
		},
		createExercice(){
			if(!this.nom || !this.series || !this.poids || !this.repetitions){
				return
			}
			let exercice = { "nom": this.nom, "series": this.series, "poids": this.poids, "repetitions" : this.repetitions}
			axios.post("https://back-gomuscu.onrender.com/exercice",exercice, headers)
			.then(response => {
				this.nom = ''
				this.series = ''
				this.poids = ''
				this.repetitions = ''
				// this.$dtoast.pop({
				//   heading: `Succès`,
				//   content:  `Exercice ajouté`,
				//   background: "#00AD13",
				//   color: "white",
				//   progress_thumb_bg : "#00AD13",
				// })
			})
			.catch(e => {
				console.log(e)
			})
		}
	}
}

</script> 


<style scoped>

.ajoutWrapper {
	margin-top: 10px;
	height: 90vh;
	justify-content: center;
}

img {
	position: absolute;
	left: 15px;
	top: 12%;
	color: #F26419 !important;
	width: 40px;
	height: 40px;
}

.form {
	margin-top: 10vh;
}

input {
	width: 60vw;
	height: 50px;
	margin: 15px;
}

.ajoutTitle{
	font-size: 26px;
	color: #F26419;
	margin-bottom: 40px;
}

.validationButton {
	width: 60vw;
	background-color: #F26419;
	height: 50px;
	border: none;
	color: white;
	margin-top: 15px;
	font-size: 22px;
	border-radius: 3px;
	font-family: Verdana !important;
}

::placeholder{
	font-size: 18px;
}

</style>
